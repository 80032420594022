@import './settings/base';

.places-table {
    cursor: pointer;
    width: 100% !important;
}

.places-table thead th {
    background-color: #8BC540 !important;
    color: white !important;
}