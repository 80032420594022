$font-base: 1rem;
$font-large: 1.2rem;
$font-xlarge: 1.5rem;
$font-small: 0.8rem;
$font-xsmall: 0.5rem;

$fontfamily-base: 'Helvetica, Arial, Calibri';

$color-base: #2F444E;
$color-link: #8BC540;
$color-danger: #C28F8F;

$line-height-base: 0.6rem;

$margin-top-base: 2rem;
$margin-left-base: 1rem;
$margin-right-base: 1rem;