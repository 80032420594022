@import './settings/base';

body {
    font-size: $font-base;
    color: $color-base;
    font-family: $fontfamily-base;
}

.page-block {
    margin-top: $margin-top-base !important;
    margin-left: $margin-left-base !important;
}